import React from "react";

const Odling = () => {
  return (
    <div>
      <iframe
        width="904"
        height="576"
        seamless
        frameBorder="0"
        scrolling="no"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTlI2JZeRJ7kRlOU6piN37-NEFmiqG4DnasGe37u-4S42Yt8OX1eTaICYpbAfbcY6voQDqQq5YGF4hr/pubchart?oid=1424747268&amp;format=interactive"
      />
    </div>
  );
};

export default Odling;
